import {
  CalendarIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClassroomDateTimePicker from '../../../../../../components/forms/DatePicker/ClassroomDateTimePicker';
import Input from '../../../../../../components/forms/Inputs/Input';
import MultipleUpload from '../../../../../../components/upload/MultipleUpload';
import { postClassroomAssignmentEvent } from '../../../../../../services/classrooms/assignmentService';
import {
  deleteClassroomEvent,
  postClassroomEvent,
} from '../../../../../../services/classrooms/eventsService';
import { getForms } from '../../../../../../services/forms/formsService';
import { getProjects } from '../../../../../../services/projects/projectService';
import AsyncSelect from 'react-select/async';
import { Controller, useForm } from 'react-hook-form';
import TextArea from '../../../../../../components/forms/Inputs/TextArea';
import { extractVideoID } from '../../../../../../utils/helpers/helpers';
import DOMPurify from 'dompurify';

export default function AssignmentForm({ section, onClose, addObject }) {
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const problemsTimer = useRef();

  const promiseProblemsOptions = (inputValue) =>
    new Promise((resolve) => {
      if (problemsTimer.current) {
        clearTimeout(problemsTimer.current);
      }
      problemsTimer.current = setTimeout(() => {
        getProjects({ search: inputValue }).then((res) =>
          resolve(res?.results.map((r) => ({ label: r?.name, value: r }))),
        );
      }, 500);

      return problemsTimer.current;
    });

  const promiseQuizOptions = (inputValue) =>
    new Promise((resolve) => {
      if (problemsTimer.current) {
        clearTimeout(problemsTimer.current);
      }
      problemsTimer.current = setTimeout(() => {
        getForms({ search: inputValue }).then((res) =>
          resolve(res?.results.map((r) => ({ label: r?.name, value: r }))),
        );
      }, 500);

      return problemsTimer.current;
    });

  const onSubmit = (data) => {
    postClassroomEvent({
      section: section,
      start_time: data.start_time,
    })?.then((event) => {
      const body = new FormData();
      body.append('name', data.name);
      body.append('content', data.content);
      body.append('weight', data.weight);
      body.append('event', event.uuid);
      body.append('video_url', data.video_url);
      if (data.attached_files?.length) {
        data.attached_files.forEach((file) => {
          body.append('attached_files', file);
        });
      }
      if (data.attached_projects?.length) {
        body.append(
          'attached_projects',
          data.attached_projects?.map((e) => e.uuid),
        );
      }
      if (data.attached_forms?.length) {
        body.append(
          'attached_forms',
          data.attached_forms?.map((e) => e.uuid),
        );
      }

      postClassroomAssignmentEvent(body)
        ?.then((assignment) => {
          addObject({ ...event, event: assignment });
          onClose();
        })
        ?.catch(() => {
          deleteClassroomEvent(event.uuid);
        })
        ?.finally(() => setIsLoading(false));
    });
  };

  return (
    <form
      className='flex flex-col h-full gap-2'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='flex flex-col px-4 gap-2'>
        <span className='text-2xl font-bold'>Assignment</span>
        <Input
          {...register('name', { required: '"Title" is required' })}
          errorMsg={errors?.name?.message}
          inputClassName='focus:outline-none'
        />
        <div className='flex flex-row justify-between'>
          <Controller
            control={control}
            name='start_time'
            defaultValue={new Date()}
            rules={{
              required: '"Start time" is required',
            }}
            render={({ field }) => (
              <div className='flex flex-row gap-1 items-center'>
                <CalendarIcon className='h-5 text-primary' />
                <ClassroomDateTimePicker
                  date={field.value}
                  setDate={field.onChange}
                />
                {errors?.start_time?.message && (
                  <span className='mt-1 text-left text-error'>
                    {errors?.start_time?.message}
                  </span>
                )}
              </div>
            )}
          />

          <div className='flex flex-row gap-1 items-center'>
            {'Weight'}
            <Input
              errorMsg={errors?.weight?.message}
              className='w-20'
              inputClassName='pr-2 text-lg focus:outline-none border-2xl'
              type='number'
              min='0'
              {...register('weight', {
                required: '"Weight" is required',
                value: 0,
              })}
            />
            {errors?.start_time?.message && (
              <span className='mt-1 text-left text-error'>
                {errors?.start_time?.message}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className='flex flex-col min-h-full bg-purple-50 w-full p-6 gap-4'>
        <div className='flex flex-col'>
          <TextArea
            className='text-sm font-medium '
            errorMsg={errors?.content?.message}
            inputClassName='border-black border rounded-xl focus:border-current focus:ring-0'
            rows={5}
            label='Assignment text'
            {...register('content', {
              required: '"Assignment text" is required',
            })}
          />
        </div>
        <Controller
          control={control}
          name='attached_files'
          defaultValue={[]}
          render={({ field }) => (
            <div>
              Attach file - limit 5mb
              <div className='bg-white p-4 border-black border rounded-xl'>
                <MultipleUpload
                  sizeLimit={5 * 1024 * 1024}
                  files={field.value}
                  setFiles={field.onChange}
                />
              </div>
            </div>
          )}
        />
        <div className='flex flex-col gap-1'>
          {'Video URL'}
          <Input
            errorMsg={errors?.weight?.message}
            type='url'
            className='w-full'
            inputClassName='pr-2 text-lg focus:outline-none border-2xl'
            min='0'
            {...register('video_url')}
          />
          {errors?.start_time?.message && (
            <span className='mt-1 text-left text-error'>
              {errors?.start_time?.message}
            </span>
          )}
        </div>
        {watch('video_url') && extractVideoID(watch('video_url')) && (
          <iframe
            className='w-full aspect-video'
            src={`https://www.youtube.com/embed/${extractVideoID(
              watch('video_url'),
            )}`}
            title={'Addtional video'}
            // allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowfullscreen='allowfullscreen'
          />
        )}
        <Controller
          control={control}
          name='attached_projects'
          defaultValue={[]}
          render={({ field }) => (
            <div>
              <div className='flex flex-row gap-1 items-center'>
                <MagnifyingGlassIcon className='h-5' />
                <AsyncSelect
                  cacheOptions
                  loadOptions={promiseProblemsOptions}
                  placeholder={t('studyplans.start_typing_to_find_project')}
                  className='w-full'
                  onChange={(e) => {
                    field.onChange([...field.value, e.value]);
                  }}
                />
              </div>
              <div className='flex flex-col gap-1 '>
                {field.value?.map((f, i) => (
                  <div
                    key={f.uuid}
                    className='flex flex-row items-center group mt-1 gap-1'
                  >
                    <span className='truncate'>{`${i + 1}. ${f.name}`}</span>
                    <XMarkIcon
                      className='h-5 p-0.5 text-error bg-red-100 rounded-full 
                      invisible group-hover:visible cursor-pointer'
                      onClick={() => {
                        field.onChange(
                          field.value?.filter((e) => e.uuid !== f.uuid),
                        );
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='attached_forms'
          defaultValue={[]}
          render={({ field }) => (
            <div>
              <div className='flex flex-row gap-1 items-center'>
                <MagnifyingGlassIcon className='h-5' />
                <AsyncSelect
                  cacheOptions
                  loadOptions={promiseQuizOptions}
                  placeholder={t('Start typing to find form')}
                  className='w-full'
                  onChange={(e) => {
                    field.onChange([...field.value, e.value]);
                  }}
                />
              </div>
              <div className='flex flex-col gap-1 '>
                {field.value?.map((f, i) => (
                  <div
                    key={f.uuid}
                    className='flex flex-row items-center group mt-1 gap-1'
                  >
                    <span className='truncate'>{`${i + 1}. ${DOMPurify.sanitize(
                      f.name,
                      {
                        USE_PROFILES: {
                          html: false,
                        },
                      },
                    )}`}</span>
                    <XMarkIcon
                      className='h-5 p-0.5 text-error bg-red-100 rounded-full 
                      invisible group-hover:visible cursor-pointer'
                      onClick={() => {
                        field.onChange(
                          field.value?.filter((e) => e.uuid !== f.uuid),
                        );
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        />
        <button
          className='bg-violet-500 text-white p-2 rounded-xl'
          type='submit'
        >
          Create assignment
        </button>
      </div>
    </form>
  );
}
