import {
  DocumentIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Input from '../../../../../../components/forms/Inputs/Input';
import TextArea from '../../../../../../components/forms/Inputs/TextArea';
import MultipleUpload from '../../../../../../components/upload/MultipleUpload';
import { getForms } from '../../../../../../services/forms/formsService';
import { getProjects } from '../../../../../../services/projects/projectService';
import AsyncSelect from 'react-select/async';
import { ListBulletIcon } from '@heroicons/react/24/solid';
import { Button } from '../../../../../../components/forms/Buttons/Button';
import { patchClassroomAssignmentEvent } from '../../../../../../services/classrooms/assignmentService';
import { extractVideoID } from '../../../../../../utils/helpers/helpers';
import DOMPurify from 'dompurify';

export default function AssignmentEditForm({
  event,
  updateEvent,
  setIsLoading,
}) {
  // const [isLoading, setIsLoading] = useState(false);
  // const [saved, setSaved] = useState(event);
  const [t] = useTranslation();

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const problemsTimer = useRef();

  const promiseProblemsOptions = (inputValue) =>
    new Promise((resolve) => {
      if (problemsTimer.current) {
        clearTimeout(problemsTimer.current);
      }
      problemsTimer.current = setTimeout(() => {
        getProjects({ search: inputValue }).then((res) =>
          resolve(res?.results.map((r) => ({ label: r?.name, value: r }))),
        );
      }, 500);

      return problemsTimer.current;
    });

  const promiseQuizOptions = (inputValue) =>
    new Promise((resolve) => {
      if (problemsTimer.current) {
        clearTimeout(problemsTimer.current);
      }
      problemsTimer.current = setTimeout(() => {
        getForms({ search: inputValue }).then((res) =>
          resolve(res?.results.map((r) => ({ label: r?.name, value: r }))),
        );
      }, 500);

      return problemsTimer.current;
    });

  const onSubmit = (data) => {
    const body = new FormData();
    body.append('name', data.name);
    body.append('content', data.content);
    body.append('weight', data.weight);
    body.append('event', event.uuid);
    body.append('video_url', data.video_url);
    if (data.attached_files?.length) {
      data.attached_files.forEach((file) => {
        body.append('attached_files', file);
      });
    }
    if (data.attached_projects?.length) {
      body.append(
        'attached_projects',
        data.attached_projects?.map((e) => e.uuid),
      );
    }
    if (data.attached_forms?.length) {
      body.append(
        'attached_forms',
        data.attached_forms?.map((e) => e.uuid),
      );
    }
    setIsLoading(true);
    patchClassroomAssignmentEvent(event.event.uuid, body)
      .then((res) => {
        updateEvent((prev) => ({ ...prev, event: res }));
      })
      ?.finally(() => setIsLoading(false));
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`flex flex-col gap-2 overflow-hidden w-full`}
    >
      <Input
        inputClassName='bg-white border border-slate-400 focus:outline-none'
        label={'Title'}
        {...register('name', {
          value: event?.event?.name,
          required: '"Title" is required',
        })}
        errorMsg={errors?.name?.message}
      />
      {event.content}
      <TextArea
        inputClassName='focus:outline-none'
        label={'Description'}
        {...register('content', {
          value: event?.event?.content,
          required: '"Description" is required',
        })}
        errorMsg={errors?.content?.message}
      />
      <div className='flex flex-row gap-1 items-center justify-end'>
        {'Weight'}
        <Input
          errorMsg={errors?.weight?.message}
          className='w-20'
          inputClassName='pr-2 text-lg focus:outline-none border-2xl'
          type='number'
          min='0'
          {...register('weight', {
            required: '"Weight" is required',
            value: event?.event?.weight,
          })}
        />
        {errors?.start_time?.message && (
          <span className='mt-1 text-left text-error'>
            {errors?.start_time?.message}
          </span>
        )}
      </div>
      <div className='flex flex-row gap-2 flex-wrap mt-2'>
        {event?.event?.attached_files?.map((file) => {
          const fileName = file.upload
            .split('/')
            ?.at(-1)
            ?.replace(`${file.uuid}_`, '');
          return (
            <a
              href={file.upload}
              target='_blank'
              rel='noreferrer'
              key={file.uuid}
            >
              <div
                className='flex flex-col justify-center p-2 rounded-2xl border w-24 h-24 text-primary hover:underline cursor-pointer group'
                title={fileName}
              >
                <DocumentIcon className='h-16 text-slate-400 group-hover:text-inherit' />
                <span className='truncate text-sm max-w-[5.5rem]'>
                  {fileName}
                </span>
              </div>
            </a>
          );
        })}
      </div>

      <Controller
        control={control}
        name='attached_files'
        defaultValue={[]}
        render={({ field }) => (
          <div>
            Attach file - limit 5mb. <b>Files will be rewrited</b>
            <div className='bg-white p-4 border-black border rounded-xl'>
              <MultipleUpload
                sizeLimit={5 * 1024 * 1024}
                files={field.value}
                setFiles={field.onChange}
              />
            </div>
          </div>
        )}
      />
      <div className='flex flex-col gap-1'>
        {'Video URL'}
        <Input
          errorMsg={errors?.weight?.message}
          type='url'
          className='w-full'
          inputClassName='pr-2 text-lg focus:outline-none border-2xl'
          min='0'
          {...register('video_url', { value: event?.event?.video_url })}
        />
        {errors?.start_time?.message && (
          <span className='mt-1 text-left text-error'>
            {errors?.start_time?.message}
          </span>
        )}
      </div>
      {(watch('video_url') ?? event?.event?.video_url) &&
        extractVideoID(watch('video_url') ?? event?.event?.video_url) && (
          <iframe
            className='w-full aspect-video'
            src={`https://www.youtube.com/embed/${extractVideoID(
              watch('video_url') ?? event?.event?.video_url,
            )}`}
            title={'Addtional video'}
            // allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowfullscreen='allowfullscreen'
          />
        )}
      <Controller
        control={control}
        name='attached_projects'
        defaultValue={event?.event?.attached_projects}
        render={({ field }) => (
          <div className='border-t py-6'>
            Problems
            <div className='flex flex-row gap-1 items-center'>
              <MagnifyingGlassIcon className='h-5' />
              <AsyncSelect
                cacheOptions
                loadOptions={promiseProblemsOptions}
                placeholder={t('studyplans.start_typing_to_find_project')}
                className='w-full'
                onChange={(e) => {
                  field.onChange([...field.value, e.value]);
                }}
              />
            </div>
            <div className='flex flex-col gap-1'>
              {field.value?.map((f, i) => (
                <a
                  key={f.uuid}
                  href={`/projects/${f.uuid}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <div
                    key={f.uuid}
                    className='flex flex-row w-full justify-between group items-center mt-1 p-2 gap-1 border rounded-2xl hover:bg-gray-200'
                  >
                    <span className='truncate group'>
                      {`${i + 1}. ${f.name}`}
                    </span>
                    <XMarkIcon
                      className='h-5 p-0.5 text-error bg-red-100 rounded-full 
                      invisible group-hover:visible cursor-pointer'
                      onClick={(e) => {
                        e.preventDefault();
                        field.onChange(
                          field.value?.filter((e) => e.uuid !== f.uuid),
                        );
                      }}
                    />
                  </div>
                </a>
              ))}
            </div>
          </div>
        )}
      />
      <Controller
        control={control}
        name='attached_forms'
        defaultValue={event.event.attached_forms}
        render={({ field }) => (
          <div className='border-t pt-6'>
            Quizzes
            <div>
              <div className='flex flex-row gap-1 items-center'>
                <MagnifyingGlassIcon className='h-5' />
                <AsyncSelect
                  cacheOptions
                  loadOptions={promiseQuizOptions}
                  placeholder={t('Start typing to find form')}
                  className='w-full'
                  onChange={(e) => {
                    field.onChange([...field.value, e.value]);
                  }}
                />
              </div>

              <div className='flex flex-row gap-2'>
                {field.value?.map((f, i) => {
                  const pureName = DOMPurify.sanitize(f.name, {
                    USE_PROFILES: {
                      html: false,
                    },
                  });
                  return (
                    <a
                      key={f.uuid}
                      href={`/forms/${f.uuid}/view`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <div
                        key={f.uuid}
                        className='flex flex-col items-center relative group mt-1 hover:bg-gray-200
                              gap-1 p-4 w-24 h-24 border rounded-2xl'
                        title={pureName}
                      >
                        <XMarkIcon
                          className='h-5 p-0.5 text-error bg-red-100 rounded-full 
                      invisible group-hover:visible cursor-pointer absolute -top-2 -right-2'
                          onClick={(e) => {
                            e.preventDefault();

                            field.onChange(
                              field.value?.filter((e) => e.uuid !== f.uuid),
                            );
                          }}
                        />
                        <div className='p-1 rounded bg-violet-500'>
                          <ListBulletIcon className='h-8 text-white' />
                        </div>
                        <span className='truncate text-sm max-w-[5.5rem]'>
                          {pureName}
                        </span>
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      />
      <Button type='submit' text='Save' color='success' />
    </form>
  );
}
