import React, { useContext } from 'react';
import { ListBulletIcon } from '@heroicons/react/24/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import bgImage from '../../../assets/images/smallImg.jpg';
import { deleteForm } from '../../../services/forms/formsService';
import { UserContext } from '../../../utils/contexts/UserContext';
import { format } from 'date-fns';
import { Button } from '../../../components/forms/Buttons/Button';
import DOMPurify from 'dompurify';

export default function FomrCard({ form, refreshData }) {
  const [t] = useTranslation();
  const { user } = useContext(UserContext);

  const handleDeleteClick = (e) => {
    e.preventDefault();
    toast.dark(
      <>
        <div>You sure want to delete content "{form.name}"?</div>
        <div className='flex justify-center mt-3 gap-3'>
          <Button
            text={'Yes'}
            color='error-solid'
            autoFocus
            onClick={() => {
              deleteForm(form.uuid)
                ?.then(() => {
                  refreshData();
                })
                ?.catch((err) => {
                  console.log(err);
                  toast.error(`Error when deleting "${form.name}"`);
                });
            }}
          />
          <Button text={'No'} className='text-white' onClick={toast.dismiss} />
        </div>
      </>,
      {
        autoClose: false,
        closeButton: true,
      },
    );
  };

  return (
    <a target='_blank' rel='noreferrer' href={`/forms/${form.uuid}/edit`}>
      <div className='relative w-72 h-48 rounded hover:border-violet-500 overflow-hidden shadow-md border'>
        <img className='w-full' src={bgImage} alt='Placeholder' />
        <div className='absolute inset-0 bg-black opacity-50'></div>
        <div className='absolute top-0 left-0 p-4 text-white'>
          <div className='font-bold text-xl mb-2'>{form?.title}</div>
          {/* <p className='text-base'>
          Author:{' '}
          {form?.author?.first_name
            ? form?.author?.first_name + ' ' + form?.author?.last_name
            : 'no author'}
        </p> */}
        </div>
        <div className='absolute flex flex-col bottom-0 left-0 w-full px-4 py-1 bg-white'>
          <div>
            <div className='inline-block mr-4 w-full'>
              <div className='truncate'>
                {DOMPurify.sanitize(form.name, {
                  USE_PROFILES: {
                    html: false,
                  },
                })}
              </div>
            </div>
          </div>
          <div className='flex flex-row gap-1 items-center justify-between'>
            <div className='flex flex-row text-slate-500 items-center text-sm gap-2 relative'>
              <div className='flex flex-col justify-center items-center h-6 w-6 bg-violet-500 rounded'>
                <ListBulletIcon className='h-5 w-5 text-white' />
              </div>
              created: {format(new Date(form.created), 'dd/MM/yyyy - hh:mm')}
            </div>
            {
              //user &&
              // (form?.author?.uuid === user?.uuid ||
              // user?.roles?.some((r) => r?.name === 'PROJECTADMIN')) ? (
              <TrashIcon
                className='p-2 h-9 text-error cursor-pointer hover:bg-red-100 rounded-full'
                title={'Delete'} // to translate
                onClick={handleDeleteClick}
              />
              // ) : undefined
            }
          </div>
        </div>
      </div>
    </a>
  );
}
