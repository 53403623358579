import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DocumentIcon, ListBulletIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { getClassroomStudents } from '../../../../../../services/classrooms/classroomService';
import Select from '../../../../../../components/forms/Select/Select';
import { getClassroomAssignmentEvents } from '../../../../../../services/classrooms/assignmentService';
import { getSubmissions } from '../../../../../../services/classrooms/submitionServise';
import SimpleLoader from '../../../../../../components/Loader/SimpleLoader';
import Review from './Review';
import DOMPurify from 'dompurify';

export default function Submissions() {
  // not used
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [student, setStudent] = useState();
  const [assignment, setAssignment] = useState();
  const [submission, setSubmission] = useState();

  useEffect(() => {
    getClassroomStudents(uuid)
      .then((data) => {
        setStudents(data.results);
        setStudent(data.results[0]?.user);
      })
      .catch((err) => {
        toast.error(t('Failed to load classroom users'));
        console.error(
          'Error loading classroom users:',
          err.response ? err.response.data : err.message,
        );
      });
    getClassroomAssignmentEvents({ classroom: uuid })?.then((res) => {
      setAssignments(res.results);
      setAssignment(res.results[0]);
    });
  }, [uuid, t]);

  useEffect(() => {
    if (assignment?.uuid && student) {
      setLoading(true);
      getSubmissions({ assignment: assignment?.uuid, user: student })
        ?.then((res) => setSubmission(res.results[0]))
        ?.finally(() => setLoading(false));
    }
  }, [assignment?.uuid, student]);

  return (
    <div className='flex flex-col gap-4 p-4'>
      <div className='flex flex-row gap-2'>
        <Select
          options={students.map((student) => ({
            label: `${student.account?.first_name} ${student.account?.last_name}`,
            value: student.user,
          }))}
          onChange={(e) => {
            setStudent(e.value);
          }}
          value={student}
          placeholder='Select students'
          className='w-52'
        />
        <Select
          options={assignments.map((assignment) => ({
            label: assignment.name,
            value: assignment,
          }))}
          value={assignment}
          onChange={(e) => {
            setAssignment(e.value);
          }}
          placeholder='Select assignment'
          className='w-52'
        />
      </div>

      {assignment && (
        <div className='flex flex-col gap-4 border-t-2 py-6'>
          <label>Assignment content</label>
          <textarea
            className='border rounded-2xl p-2 shadow-xl'
            readOnly
            value={assignment.content}
            style={{ resize: 'none' }}
          />
          {!!assignment?.attached_files?.length && (
            <div className='border-t-2 py-6'>
              Attached file
              <div className='flex flex-row gap-2 flex-wrap'>
                {assignment?.attached_files?.map((file) => {
                  const fileName = file.upload
                    .split('/')
                    ?.at(-1)
                    ?.replace(`${file.uuid}_`, '');
                  return (
                    <a
                      href={file.upload}
                      target='_blank'
                      rel='noreferrer'
                      key={file.uuid}
                    >
                      <div
                        className='flex flex-col justify-center p-2 rounded-2xl border w-24 h-24 
                      text-primary hover:underline cursor-pointer group hover:bg-gray-200'
                        title={fileName}
                      >
                        <DocumentIcon className='h-16 text-slate-400 group-hover:text-inherit' />
                        <span className='truncate text-sm'>{fileName}</span>
                      </div>
                    </a>
                  );
                })}
              </div>{' '}
            </div>
          )}
          {!!assignment?.attached_projects?.length && (
            <div className='border-t-2 py-6'>
              Problems
              <div className='flex flex-col gap-1 '>
                {assignment?.attached_projects?.map((f, i) => {
                  const projectSubmision = submission?.project_submissions
                    ?.filter((p) => p.project === f.uuid)
                    ?.sort((a, b) => (a.updated < b.updated ? 1 : -1))
                    ?.at(0)?.uuid;

                  // ?.at(0)?.uuid)
                  return (
                    <div
                      key={f.uuid}
                      className='flex flex-row justify-between items-center 
                    group mt-1 gap-1 overflow-hidden border rounded-2xl hover:bg-gray-200'
                    >
                      <a
                        href={`/projects/${f.uuid}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <span className='truncate p-2'>{`${i + 1}. ${
                          f.name
                        }`}</span>
                      </a>

                      {projectSubmision && (
                        <a
                          href={`/submissions/${projectSubmision}`}
                          target='_blank'
                          rel='noreferrer'
                          className='bg-primary text-white h-10 px-2 flex flex-col justify-center
                                    cursor-pointer'
                          onClick={(e) => {}}
                        >
                          view submission
                        </a>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {!!assignment?.attached_forms?.length && (
            <div className='border-t-2 py-6'>
              Quizzes
              <div className='flex flex-row gap-2'>
                {assignment?.attached_forms?.map((f, i) => {
                  const pureName = DOMPurify.sanitize(f.name, {
                    USE_PROFILES: {
                      html: false,
                    },
                  });
                  return (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`/forms/${f.uuid}/edit`, {
                          state: { tab: 1, user: student },
                        });
                      }}
                      key={f.uuid}
                      className='flex flex-col items-center group mt-1 hover:bg-gray-200
                        gap-1 p-4 w-24 h-24 border rounded-2xl'
                      title={pureName}
                    >
                      <div className='p-1 rounded bg-violet-500'>
                        <ListBulletIcon className='h-8 text-white' />
                      </div>
                      <span className='truncate text-sm'>{pureName}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {submission ? (
            <div className='border-t-2 pt-6'>
              {'Student submission'}
              <div className='flex flex-row gap-2 flex-wrap my-2'>
                {submission?.attached_files?.map((file) => {
                  const fileName = file.upload
                    .split('/')
                    ?.at(-1)
                    ?.replace(`${file.uuid}_`, '');
                  return (
                    <a
                      key={file.uuid}
                      href={file.upload}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <div
                        className='flex flex-col justify-center p-2 rounded-2xl border w-24 h-24 
                      text-primary hover:underline cursor-pointer group hover:bg-gray-200'
                        title={fileName}
                      >
                        <DocumentIcon className='h-16 text-slate-400 group-hover:text-inherit' />
                        <span className='truncate text-sm'>{fileName}</span>
                      </div>
                    </a>
                  );
                })}
              </div>
              <textarea
                readOnly
                className='focus:outline-none w-full'
                style={{ resize: 'none' }}
                label={'Description'}
                value={submission?.content}
              />
            </div>
          ) : (
            <div>No submission</div>
          )}
          <div className='relative border-t-2 p-2'>
            {loading ? (
              <SimpleLoader className='h-5' />
            ) : (
              student && submission && <Review submission={submission} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
